<template>
  <section>
    <div class="ll-box d-flex">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Master Data') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Self-pick List') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- button -->
      <b-row class="mb-1 justify-content-end">
        <b-col cols="2" class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="mr-1"
            v-b-modal.modal-upload
          >
            Upload
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-input-group>
              <b-input-group-prepend
                is-text
                v-b-toggle.filterS
                class="ll-outline">
                 <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-form-input
                v-model="condition.locationid"
                class="searchInput"
                placeholder="Search enter location code"
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                @click="searchById"
                >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title="" right shadow backdrop bg-variant="white">
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item :title="$t('Service provider')">
            <v-select
              id="customer"
              v-model="agent"
              :options="agentList"
              label="agent"
              @input="selServiceP"
              placeholder=""
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Area')">
            <v-select
              v-model="area"
              :options="areaList"
              label="area"
              @input="selArea"
              placeholder=""
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Status')">
            <v-select
              id="isActive"
              :options="statusList"
              placeholder=""
              @input="selStatus"
            ></v-select>
          </app-collapse-item>
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            @click="filterSearch">
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      responsive
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-permission="[194]"
      :sticky-header="true"
      :busy="isBusy"
      @row-clicked="onDetail"
      :no-border-collapse="true"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <feather-icon
          :id="`${data.item.id}`"
          icon="EditIcon"
          size="16"
          class="mx-1"
          @click="$router.push({ name: 'self-pick-detail', params: { id: data.item.id }})"
          v-permission="[196]"
        />
        <b-tooltip
          title="Edit"
          :target="`${data.item.id}`"
        />
      </template>
      <template #cell(status)="row">
        <b-badge
          :variant="(row.value ==='ACTIVE') ? 'light-info' : 'light-secondary'"
          class="ll-badge">
          <span>{{ row.value }}</span>
        </b-badge>
      </template>
    </b-table>

    <div class="d-flex justify-content-between flex-wrap ll-p">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <!-- upload -->
    <b-modal
      id="modal-upload"
      cancel-variant="outline-secondary"
      ok-title="Sure"
      centered
      title=""
      v-model="show"
    >
      <el-upload
        class="upload-demo ll-upload"
        drag
        :action="baseURL"
        accept=".csv,.xlsx"
        :headers="myHeaders"
        :on-success="uploadSuccess"
        :on-error="uploadFail"
        :on-progress="uploadPro"
        ref="upload"
        :auto-upload="false"
        :limit="1">
        <!-- <i class="el-icon-upload"></i> -->
        <div class="el-upload__text" v-if="isDefault">
          {{uploadText}}
        </div>
        <div v-if="uLoading" class="el-upload__text">
          <p class="ll-uploadText">Uploading...</p>
          <b-spinner label="Loading..." />
        </div>
        <div v-if="uSuccess" class="el-upload__text">
          <svg aria-hidden="true" class="ll-success ll-uploadIcon">
            <use xlink:href="#icon-form_icon_normal"></use>
          </svg><br>
          <span class="ll-uploadText ll-success">Upload success</span>
        </div>
        <div v-if="uFail" class="el-upload__text ll-upload">
          <svg aria-hidden="true" class="ll-fail ll-uploadIcon">
            <use xlink:href="#icon-delete"></use>
          </svg><br>
          <span class="ll-uploadText ll-fail">Upload failed</span><br>
          <span class="ll-uploadText">ERROR: SKU {XXX} not found</span>
        </div>
        <div class="el-upload__tip" slot="tip">
          <b-link @click="downTem">Download template</b-link> here<br>
          Only accept CSV,xlsx etc
        </div>
      </el-upload>
      <template #modal-footer>
        <b-button
          variant="primary"
          class="btn"
          @click="onUpload"
        >
          {{ $t('Upload') }}
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  VBToggle,
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BBadge, BSpinner,BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'
import { debounce } from "@/libs/fun.js"
import axios from 'axios'

export default {
  components: {
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BSpinner,
    BLink,
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'agent', label: this.$t('Service provider'), sortable: true },
        { key: 'locationid', label: this.$t('Location code'), sortable: true },
        { key: 'areaCn', label: this.$t('Area'), sortable: true },
        { key: 'storename', label: this.$t('Name'), sortable: true },
        { key: 'status', label: this.$t('Status'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      statusList: ['NEW', 'ACTIVE', 'INACTIVE'],
      condition: {
        page: '1',
        size: '50',
        orderMole: 1,
        orderBy: 'id',
        locationid: null,
        agent: null,
        area: null,
        status: null,
      },
      agentList: [],
      areaList: [],
      agent: '',
      area: '',
      isBusy: true,
      uploadText: 'Upload file here(drag the file or select from computer)',
      isDefault: true,
      uLoading: false,
      uSuccess: false,
      uFail: false,
      show: false,
      baseURL:axios.defaults.baseURL+'/import/uploadLmdOrder/SELFPICKLIST',
      myHeaders:{Authorization:JSON.parse(localStorage.getItem('userInfo')).token}
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.getList()
    this.getAgentList()
    this.getAreaList()
  },
  methods: {
    // 首次查询
    getList() {
      this.$http.post('/selfpickLocation/search', this.condition).then(res => {
        // console.log('ss', res.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.isBusy = false
      }).catch(error => console.log(error))
    },
    onClear() {
      this.condition = {}
    },
    // 条件过滤搜索
    filterSearch() {
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      console.log(orderBy, orderMole)
      return params.sortDesc ? 0 : 1
    },
    searchById() {
      // this.condition.locationid = this.condition.locationid.trim()
      this.getList()
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    getAgentList() {
      this.$http.get('selfpickLocation/getAgentList').then(res => {
        this.agentList = res.data.data
      })
    },
    getAreaList() {
      this.$http.get('selfpickLocation/getAreaList').then(res => {
        const list = res.data.data
        list.map(i=>{
          if (i) {
            this.areaList.push(i)
          }
        })
      })
    },
    selServiceP(d) {
      this.agent = d ? d.agent : null
      this.condition.agent = d ? d.agent : null
    },
    selArea(d) {
      this.area = d ? d.area : null
      this.condition.area = d ? d.area : null
    },
    selStatus(d) {
      this.condition.status = d ? d : null
    },
    onDetail(item){
      this.$router.push({ name: 'self-pick-detail', params: { id: item.id }})
    },
    uploadSuccess(response, file, fileList) {
      console.info('in')
      // console.log(response, file, fileList)
      // let files = new File([JSON.stringify(file.name)],{type:'application/json'})
      // let files = file.raw
      // let fd = new FormData
      // fd.append('file', files)
      // this.$http.post('/import/uploadLmdOrder/SELFPICKLOCATION',fd).then(res => {
      //   if (res.data.code === '200') {
          this.show = false
          this.$router.push({ name: 'upload-order' })
        // }
      // })
      this.isDefault = false
      this.uLoading = false
      this.uSuccess = true
      this.uFail = false
    },
    uploadFail() {
      this.isDefault = false
      this.uLoading = false
      this.uSuccess = true
      this.uFail = false
    },
    uploadPro() {
      this.isDefault = false
      this.uLoading = true
      this.uSuccess = false
      this.uFail = false
    },
    downTem: debounce(function() {
      let url = axios.defaults.baseURL+`/import/template/SELFPICKLIST`
      window.open(url, "_self")
    }, 1000),
    onUpload() {
      this.$refs.upload.submit()
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: fixed;
  bottom: 40px;
  right: 10px;
} */
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-uploadIcon{
  width: 5em;
  height: 5em;
}
.ll-success{
  fill: #28c76f;
}
.ll-fail{
  fill: #ea5455;
}
.ll-uploadText{
  font-weight: bold;
  font-size: 24px;
}
</style>
<style>
.ll-upload .el-upload-dragger{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ll-upload .el-upload{
  width: 100%;
}
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
